import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getSingle_User } from '../../../../../../../../../redux/actions/userAction';
import { FormControl } from '@mui/joy';
import { API } from '../../../../../../../../../backend';
import axios from 'axios';

export default function DesignerDropdown({ popUpDesignerId, users, notifyDesigner, formData, setFormData }) {
  const dispatch = useDispatch()
  const [designerId, setDesignerId] = React.useState(null)
  const userDetails = useSelector((state) => state.user.userDetail);
  const singleUser = useSelector((state) => state.user.singleUser)
  const getState = useSelector((state) => state);
  const businessId = JSON.parse(localStorage.getItem('businessId'))

   

  React.useEffect(() => {
    dispatch(getSingle_User(userDetails?._id))
  }, [userDetails])

  //details from business id only
  const [currentDetails, setCurrentDetails] = React.useState(null)
  //filtering designers only
  const designers = users?.filter((user) => user?.Role === 'designer')
  React.useEffect(() => {
    const getSingleUser = async () => {
      await axios.get(`${API}auth/getsingleUser/${businessId}`)
        .then(({ data }) => setCurrentDetails(data))
    }
    getSingleUser()
  }, [])
  return (
    <FormControl>
      <Select
        required
        defaultValue="Eric"
        placeholder={<>
          select your designer {' '} <span className=' text-red-600'> {" "}*</span>
        </>}
        slotProps={{
          listbox: {
            sx: {
              '--ListItemDecorator-size': '48px',
            },
          },
        }}
        sx={{
          minWidth: 240,
          mb: 1
        }}
      >
        {designers?.map((data, index) => (
          <Option
            sx={{
              cursor: (popUpDesignerId && popUpDesignerId !== data?._id) ? 'not-allowed' : 'pointer',
              backgroundColor: (popUpDesignerId && popUpDesignerId === data?._id) ? '#c8c7c9' : 'whitesmoke',
              opacity: (popUpDesignerId && popUpDesignerId !== data?._id) ? 0.4 : 1
            }}
            disabled={((popUpDesignerId && popUpDesignerId !== data?._id && formData?.templateId) || (formData?.designerId && formData?.designerId !== data?._id && formData?.templateId)) ? true : false}
            onClick={() => {
              notifyDesigner(data?._id)
              setFormData({ ...formData, designerId: `${data?._id}` })
              setDesignerId(`${data?._id}`)

            }}
            key={data.name}
            value={data.name}
            label={data.name} // The appearance of the selected value will be a string
          >
            <ListItemDecorator>
              <Avatar src={data.name} alt={data.name} />
            </ListItemDecorator>
            <Box component="span" sx={{ display: 'block' }}>
              <Typography component="span" level="title-sm">
                {data.name}
              </Typography>
              <Typography level="body-xs">{data.status}</Typography>
            </Box>
            <Chip
              size="sm"
              variant="outlined"
              color='neutral'
              sx={{
                ml: 'auto',

                minHeight: '20px',
                paddingInline: '4px',
                fontSize: 'xs',
                bgcolor: 'yellow',
              }}
            >
              {data.Role}
            </Chip>
          </Option>
        ))}
      </Select>
    </FormControl>
  );
}