const initialData = {

    currentProjectId: null,
    showDot: false,
    reworkChat: '',
    selectedForm: ''
}

const handleChat = (state = initialData, action) => {
    switch (action.type) {
        case "SELECTED_PROJECT":
            return {
                ...state,
                currentProjectId: action.payload
            }
        case "SHOW_DOT":
            return {
                ...state,
                showDot: action.payload
            }
        case "HIDE_DOT":
            return {
                ...state,
                showDot: action.payload
            }

        case "REWORK_CHAT":
            return {
                ...state,
                reworkChat: action.payload.chat,
                selectedForm: action.payload.selectedForm
            }

        default: return state
    }
}
export default handleChat