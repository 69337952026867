import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSingle_User } from '../../redux/actions/userAction';
import axios from 'axios';
import { API } from '../../backend';
import { getAllforms } from '../../redux/actions/designerFormAction';
import Chat from './Chat';
import ChatContainer from './createChat/ChatContainer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import TeamChat from './TeamChat';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Tooltip from '@mui/material/Tooltip'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AdminTeamChat from './createChat/AdminTeamChat';
import { CircularProgress } from '@mui/material';

export default function Chats() {
  const { id } = useParams()
  const selectedProject = useSelector((state) => state.chat.currentProjectId)
  const navigate = useNavigate()
  const [pathName, setPathName] = useState(null);
  console.log("pathname is ====>>>> :", pathName)
  let { pathname } = useLocation();
  useEffect(() => {
    console.log("pathname :", pathname)
    setPathName(pathname);
  }, [pathname]);

  const [projectDetail, setProjectDetail] = useState(null)

  useEffect(() => {
    if (selectedProject) {
      setProjectId(selectedProject)
      setProjectDetail(selectedProject)
    }
  }, [selectedProject])

  useEffect(() => {
    if (!projectDetail && !selectedProject) {
      navigate('/chats')
    }
  }, [])

  const [projectId, setProjectId] = useState(null)
  const businessId = JSON.parse(localStorage.getItem('businessId'))
  const dispatch = useDispatch()
  const singleUser = useSelector((state) => state.user.singleUser)
  const userDetails = useSelector((state) => state.user.userDetail);
  const getState = useSelector((state) => state);

   

  useEffect(() => {
    dispatch(getSingle_User(userDetails?._id))
  }, [])

  const [chatData, setChatData] = useState({
    text: '',
    demoImgUrl: '',
    myId: `${userDetails?._id}`,
    formId: '',
    sendername: `${userDetails?.emailid}`,
    designerId: '',
    designername: ''
  })

  useEffect(() => {
    setChatData({
      ...chatData,
      myId: `${userDetails?._id}`,
      sendername: `${userDetails?.name}`,
      formId: `${projectDetail?._id}`,
      designerId: `${projectDetail?.designerId?._id}`,
      designername: `${projectDetail?.designerId?.name}`,
    })
  }, [userDetails, projectDetail])

  //details from business id only
  const [currentDetails, setCurrentDetails] = useState(null)

  useEffect(() => {
    const getSingleUser = async () => {
      await axios.get(`${API}auth/getsingleUser/${businessId}`)
        .then(({ data }) => setCurrentDetails(data))
    }
    getSingleUser()
  }, [])

  const fs = useSelector(state => state.form.form)
  useEffect(() => {
    dispatch(getAllforms())
  }, [])

  const [searchTerm, setSearchTerm] = useState('')
  //for admin/client
  const myForms = fs?.filter((f) => f?.myId?._id === userDetails?._id)

  //for corporate members only
  const memberForms = fs?.filter((f) => f?.myId?._id === businessId)

  //for designer
  const designerForms = fs?.filter((f) => f?.designerId?._id === userDetails?._id)



  // for member only to show those projects only where they are added
  const filteredFs = fs.filter((f) => {
    return f?.team.find((mail) => mail === userDetails?.emailid) ||
      userDetails?.team.find((mail) => mail === f?.myId?.emailid);
  });

  const memberProjects = myForms?.filter((f) => f?.team.find((mail) => mail === userDetails?.emailid))
  // console.log('member projects are======>>', memberProjects)
  // console.log('member projects are======>>', myForms)
  console.log('member projects are======>>', filteredFs)
  // console.log('member projects are======>>', memberForms)
  //finding those projects where inside of team userDetails?.emailid is present


  const searchProject = (forms) => {
    return forms?.filter((item) =>
      item?.title?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item?.projectDesc?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      item?.myId?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    )
  }

  const searchedProjects = searchProject(myForms)

  const searchedDesignerProjects = searchProject(designerForms)

  const [showProjects, setShowProjects] = useState(false)

  return (

    <div className=' mx-auto relative flex justify-center max-w-[1200px] mt-[17px]'>
      {/* projects */}
      <Tooltip
        onClick={() => setShowProjects(true)}
        title="See your Projects">
        <div className=' md:hidden block cursor-pointer mr-2 mt-2 absolute right-0 top-0 rounded-full p-1'>
          <ExpandCircleDownIcon />
        </div>
      </Tooltip>

      {/* for mobile */}
      {
        showProjects &&
        <div className=' absolute top-0 right-0 z-[99] bg-zinc-300 rounded-lg p-3  md:hidden flex flex-col gap-2 w-[330px] lg:p-2 md:p-2 xl:p-0'>
          <Tooltip
            onClick={() => setShowProjects(false)}
            title="Back">
            <div className=' absolute right-0 top-0 m-4'>
              <ArrowBackIcon />
            </div>
          </Tooltip>

          <input
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='search projects'
            className=' p-2 rounded-lg outline-none border-[1px] border-indigo-100 focus:border-indigo-500'
            type="text" />

          <div className=' h-[calc(100vh-180px)]  overflow-y-auto overflow-x-hidden flex flex-col gap-2'>

            {/* for client and corporate members */}

            {businessId && <p className=' mb-[-3px] mt-1 font-semibold text-lg' >My Projects</p>}
            {searchTerm?.length === 0 && userDetails?.Role !== 'designer' &&
              myForms?.map((f, idx) => (

                <Chat
                  chatData={chatData} setChatData={setChatData}
                  setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
              ))
            }
            {searchTerm?.length > 0 && userDetails?.Role !== 'designer' &&
              searchedProjects?.map((f, idx) => (

                <Chat
                  chatData={chatData} setChatData={setChatData}
                  setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
              ))
            }


            {/* showing admin projects to team members */}
            {businessId && <p className=' mb-[-3px] mt-1 font-semibold text-lg'>Team Projects</p>}
            {businessId && userDetails?.Role !== 'designer' &&
              memberForms?.map((f, idx) => (
                <TeamChat
                  chatData={chatData} setChatData={setChatData}
                  setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
              ))
            }

            {/* showing team projects to admin */}
            {!businessId && userDetails?.Role === "corporate_admin" && <p className=' mb-[-3px] mt-1 font-semibold text-lg'>Team Projects</p>}
            {!businessId && userDetails?.Role === "corporate_admin" &&
              memberForms?.map((f, idx) => (
                <TeamChat
                  chatData={chatData} setChatData={setChatData}
                  setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
              ))
            }

            {/* for designers */}
            {searchTerm?.length === 0 && userDetails?.Role === 'designer' &&
              designerForms?.map((f, idx) => (

                <Chat
                  chatData={chatData} setChatData={setChatData}
                  setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
              ))
            }
            {searchTerm?.length > 0 && userDetails?.Role === 'designer' &&
              searchedDesignerProjects?.map((f, idx) => (

                <Chat
                  chatData={chatData} setChatData={setChatData}
                  setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
              ))
            }




            {
              searchTerm && (searchedProjects?.length === 0 && searchedDesignerProjects?.length === 0) &&

              <div className=' flex justify-center items-center mx-auto text-lg font-bold'>
                No Projects are available
              </div>
            }
          </div>
        </div>}

      {/* for desktop */}
      <div className='  md:flex hidden flex-col gap-2 lg:w-[350px] md:w-[290px] lg:p-2 md:p-2 xl:p-0'>
        <input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='search projects'
          className=' p-2 max-w-[330px] rounded-lg outline-none border-[1px] border-indigo-100 focus:border-indigo-500'
          type="text" />

        <div className=' lg:h-[calc(100vh-160px)] md:h-[calc(100vh-180px)]  overflow-y-auto overflow-x-hidden flex flex-col gap-2'>

          {/* for client and corporate members */}

          {businessId && <p className=' mb-[-3px] mt-1 font-semibold text-lg' >My Projects</p>}
          {searchTerm?.length === 0 && userDetails?.Role !== 'designer' &&
            myForms?.map((f, idx) => (

              <Chat
                chatData={chatData} setChatData={setChatData}
                setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
            ))
          }
          {searchTerm?.length > 0 && userDetails?.Role !== 'designer' &&
            searchedProjects?.map((f, idx) => (

              <Chat
                chatData={chatData} setChatData={setChatData}
                setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
            ))
          }

          {businessId && <p className=' mb-[-3px] mt-1 font-semibold text-lg'>Team Projects</p>}
          {businessId && userDetails?.Role !== 'designer' &&
            memberForms?.map((f, idx) => (

              <TeamChat
                chatData={chatData} setChatData={setChatData}
                setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
            ))
          }

          {/* showing team projects to admin */}
          {!businessId && userDetails?.Role === "corporate_admin" && (
            <>
              <p className=' mb-[-3px] mt-1 font-semibold text-lg'>Team Projects</p>
              {filteredFs?.length > 0 ? (
                filteredFs.map((f, idx) => (
                  <AdminTeamChat
                    key={idx} // always use a unique key when mapping
                    chatData={chatData}
                    setChatData={setChatData}
                    setShowProjects={setShowProjects}
                    projectDetail={projectDetail}
                    userDetails={singleUser}
                    setProjectDetail={setProjectDetail}
                    projectId={projectId}
                    setProjectId={setProjectId}
                    idx={idx}
                    f={f}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center">
                  <CircularProgress /> {/* MUI loader */}
                </div>
              )}
            </>
          )}
          {/* for designers */}
          {searchTerm?.length === 0 && userDetails?.Role === 'designer' &&
            designerForms?.map((f, idx) => (

              <Chat
                chatData={chatData} setChatData={setChatData}
                setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
            ))
          }
          {searchTerm?.length > 0 && userDetails?.Role === 'designer' &&
            searchedDesignerProjects?.map((f, idx) => (

              <Chat
                chatData={chatData} setChatData={setChatData}
                setShowProjects={setShowProjects} projectDetail={projectDetail} userDetails={singleUser} setProjectDetail={setProjectDetail} projectId={projectId} setProjectId={setProjectId} idx={idx} f={f} />
            ))
          }




          {
            searchTerm && (searchedProjects?.length === 0 && searchedDesignerProjects?.length === 0) &&

            <div className=' flex justify-center items-center mx-auto text-lg font-bold'>
              No Projects are available
            </div>
          }
        </div>
      </div>
      <div className=' bg-slate-100 lg:w-[69%] lg:h-[calc(100vh-100px)] md:p-0 p-1.5 rounded-md h-[calc(100vh-120px)]   w-full'>
        {
          pathName !== '/chats' ?
            <ChatContainer
              chatData={chatData} setChatData={setChatData}
              selectedProject={selectedProject}
              projectDetail={projectDetail} userDetails={singleUser} setProjectId={setProjectId} /> :
            <div className=' flex justify-center flex-col font-bold text-3xl items-center pt-[calc(50vh-110px)]'>
              Click one project to chat
              <div className=' relative'>
                <div className=' absolute top-1.5 right-[-24px] w-[20px] z-50 animate-pulse bg-red-500 h-[20px] rounded-full'></div>
                <ChatBubbleOutlineIcon className=' scale-[3] mt-8' />
              </div>
            </div>
        }
      </div>
    </div>
  )
}
